<style lang="less" scoped>
.idc {
  width: 100%;
  background: #070911;
  &-first {
    width: 100%;
    position: relative;
    font-family: "PingFangSC-Regular", "PingFang SC", "PingFangSC-Semibold",
      "PingFang SC";
    img {
      width: 100%;
    }
    &-container {
      width: 76.7%;
      max-width: 1104px;
      color: rgba(255, 255, 255, 1);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -44%);
      p:nth-child(1) {
        font-size: 20px;
        font-weight: 400;
        color: rgba(249, 98, 47, 1);
        letter-spacing: 3px;
      }
      p:nth-child(2) {
        font-size: 55px;
        font-weight: 600;
        margin: 0;
      }
      p:nth-child(3) {
        font-size: 40px;
        font-weight: 500;
        letter-spacing: 5px;
        margin: 0 0 67px 0;
      }
      p:nth-child(5) {
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 260px;
      }
      .button-wrapper {
        // width: 42%;
        width: 30%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  &-second {
    width: 100%;
    height: 658px;
    background: #070911;
    position: relative;
    &-container {
      width: 76.7%;
      max-width: 1104px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: space-between;
      &-left {
        width: 49.8%;
        img {
          width: 100%;
        }
      }
      &-right {
        width: 42.2%;
        color: #fff;
        color: rgba(222, 230, 255, 1);
        &-title {
          font-size: 30px;
          font-weight: 400;
          line-height: 42px;
          letter-spacing: 4px;
          margin: 0;
        }
        &-sub-title {
          font-size: 10px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 4px;
          margin: 0;
          margin-bottom: 20px;
        }
        &-main-item {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 1px;
          p:nth-child(1) {
            text-align: left;
            width: 30%;
            font-size: 14px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 1px;
            margin: 0;
          }
          p:nth-child(2) {
            text-align: left;
            width: 70%;
            font-size: 14px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 1px;
            margin: 0;
          }
        }
        .r-button-4 {
          margin-top: 60px;
        }
      }
    }
    &-title {
      color: rgba(222, 230, 255, 1);
      text-align: center;
      padding-top: 90px;
      font-size: 30px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 4px;
      margin: 0;
    }
    &-subtitle {
      color: rgba(222, 230, 255, 1);
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 4px;
      margin: 0;
      margin-top: 4px;
    }
  }
  &-third {
    width: 100%;
    height: 650px;
    background: #0f1118;
    position: relative;
    color: rgba(222, 230, 255, 1);
    text-align: center;
    padding-top: 90px;
    &-title {
      font-size: 30px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 4px;
      margin: 0;
    }
    &-container {
      width: 65.2%;
      height: 288px;
      overflow: scroll;
      max-width: 940px;
      margin: 50px auto;
      display: flex;
      justify-content: space-around;
      &-item {
        width: 33%;
        text-align: center;
        img {
          width: 30px;
        }
        p:nth-child(2) {
          font-size: 20px;
          font-weight: 400;
          line-height: 28px;
          margin: 0;
        }
        p:nth-child(3) {
          font-size: 14px;
          font-weight: 400;
          line-height: 30px;
        }
      }
    }
  }
  &-forth {
    width: 100%;
    height: 811px;
    background: #070911;
    position: relative;
    &-container {
      width: 76.7%;
      height: 650px;
      max-width: 1104px;
      overflow: hidden;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      &-title {
        width: 100%;
        // height: 100%;
        height: 60px;
        position: absolute;
        top: 0;
        left: 0;
        // background: rgba(7, 9, 17, 0.59);
        color: #dee6ff;
        z-index: 99999;
        background: rgba(7, 9, 17, 0.8);
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        &-item {
          width: 33.3%;
          text-align: center;
          font-size: 20px;
          font-weight: 400;
          line-height: 60px;
          letter-spacing: 2px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
        .title-actived {
          border: 4px solid;
          border-image: linear-gradient(
              270deg,
              rgba(85, 101, 251, 1),
              rgba(5, 153, 251, 1) 100%
            )
            1;
        }
      }
      .swiper-slide {
        width: 100%;
        position: relative;
        // img {
        //   width: 100%;
        // }
        &-video {
          width: 100%;
          height: 650px;
        }
        &-hover {
          width: 100%;
          // height: 100%;
          height: 60px;
          position: absolute;
          top: 0;
          left: 0;
          // background: rgba(7, 9, 17, 0.59);
          color: #dee6ff;
          z-index: 99999;
          &-title {
            width: 100%;
            height: 60px;
            background: rgba(7, 9, 17, 0.8);
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            &-item {
              width: 33.3%;
              text-align: center;
              font-size: 20px;
              font-weight: 400;
              line-height: 60px;
              letter-spacing: 2px;
              overflow: hidden; //超出的文本隐藏
              text-overflow: ellipsis; //溢出用省略号显示
              white-space: nowrap; //溢出不换行
            }
          }
          &-container {
            text-align: center;
            cursor: pointer;
            img {
              width: 36px;
              display: block;
              margin: 0 auto;
              margin-top: 121px;
            }
            p:nth-child(2) {
              font-size: 16px;
              font-weight: 600;
              letter-spacing: 2px;
            }
            p:nth-child(3) {
              font-size: 14px;
              font-weight: 400;
              line-height: 30px;
            }
          }
          .title-actived {
            border: 4px solid;
            border-image: linear-gradient(
                270deg,
                rgba(85, 101, 251, 1),
                rgba(5, 153, 251, 1) 100%
              )
              1;
          }
        }
      }
    }
  }
  &-fifth {
    width: 100%;
    height: 800px;
    background: #0f1118;
    position: relative;
    color: rgba(222, 230, 255, 1);
    text-align: center;
    padding-top: 90px;
    &-title {
      font-size: 30px;
      font-weight: 400;
      line-height: 42px;
      letter-spacing: 4px;
      margin: 0;
    }
    &-subtitle {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 4px;
      margin: 0;
    }
    &-container {
      width: 72.8%;
      height: 380px;
      max-width: 1049px;
      margin: 50px auto;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      // position: relative;
      &-item {
        width: 22%;
        height: 100%;
        background: #070911;
        &-top {
          width: 70px;
          height: 70px;
          background: center / contain no-repeat url("../assets/idc_circle.png");
          position: relative;
          margin: 35px auto 16px;
          // border: 2px solid;
          // border-radius: 50%;
          // border-image: linear-gradient(45deg, gold, deeppink) 1;
          // clip-path: circle(50%);
          img {
            width: 80%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        p {
          margin: 0;
          font-family: "PingFangSC-Regular", "PingFang SC";
          color: rgba(222, 230, 255, 1);
          &:nth-child(2),
          &:nth-child(3) {
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
          }
          &:nth-child(4) {
            // width: 94%;
            // margin-left: 3%;
            margin-top: 16px;
            font-size: 10px;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }
  }
  .r-button {
    width: 140px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 29px;
  }
  .r-button-3 {
    margin: 58px 0;
  }
  .r-button-4 {
    margin: 78px auto;
  }
  .r-button-5 {
    margin: 0 auto;
  }
  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    transition: opacity 3s ease;
  }
  .r-button-wrapper {
    width: 30%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }
  .fearless-center-forth-container {
    width: 76.7%;
    // height: 76%;
    max-width: 1104px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &-item {
      width: 30%;
      background: rgba(15, 17, 24, 1);
      margin-top: 90px;
      img {
        width: 100%;
      }
      .second-second {
        padding: 34px;
        color: rgba(222, 230, 255, 1);
        p:nth-child(1) {
          font-size: 20px;
          font-weight: 400;
          line-height: 28px;
        }
        p:nth-child(2) {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
        }
        p:nth-child(3) {
          width: 90px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          font-size: 12px;
          line-height: 30px;
          border-radius: 4px;
          border: 1px solid rgba(222, 230, 255, 1);
          cursor: pointer;
        }
      }
    }
  }
}
</style>

<template>
  <div class="idc">
    <div class="idc-first">
      <img src="../assets/home_IDC.png"
           alt="">
      <div class="idc-first-container">
        <p>
          " POWER OF DESIGN " {{$t('IDC_title2')}}
        </p>
        <p>
          INTERNATIONAL DESIGN CONTEST
        </p>
        <p>
          {{$t('IDC_title1')}}
        </p>
        <div class="button-wrapper">
          <!-- <div class="r-button"
               @click="toWork('design')">
            {{ $t('signUp') }}
          </div> -->
          <div class="r-button"
               @click="toRoles">
            {{ $t('rules') }}
          </div>
          <div class="r-button"
               @click="toProduction('design')">
            {{ $t('Q67') }}
          </div>
        </div>
        <p>
        </p>
      </div>
    </div>
    <div class="idc-second">
      <div class="idc-second-container">
        <div class="idc-second-container-left">
          <img src="../assets/home_idc2.jpg"
               alt="">
        </div>
        <div class="idc-second-container-right">
          <p class="idc-second-container-right-title"
             v-show="$Cookies.get('cecc-lang') !== 'en'">大赛简介</p>
          <p class="idc-second-container-right-sub-title">INTRODUCTION</p>
          <div class="idc-second-container-right-main"
               v-if="$Cookies.get('cecc-lang') !== 'en'">
            <div class="idc-second-container-right-main-item">
              <p>
                {{$t('IDC_second1')}}
              </p>
              <p>
                {{$t('IDC_second1_1')}}
              </p>
            </div>
            <div class="idc-second-container-right-main-item">
              <p>
                {{$t('IDC_second2')}}
              </p>
              <p>
                {{$t('IDC_second2_1')}}
              </p>
            </div>
            <div class="idc-second-container-right-main-item">
              <p>
                {{$t('IDC_second3')}}
              </p>
              <p>
                {{$t('IDC_second3_1')}}
              </p>
            </div>
            <div class="idc-second-container-right-main-item">
              <p>
                {{$t('IDC_second4')}}
              </p>
              <p>
                {{$t('IDC_second4_1')}}
              </p>
            </div>
          </div>
          <div class="idc-second-container-right-main"
               v-else>
            <div class="idc-second-container-right-main-item">
              Contribute to the development of the YRD .<br />

              Encourage and support international collaboration in innovation and creativity .<br />

              Promote green technology to underpin a sustainable future and contribute to designing a green world .<br />

              Give an opportunity for existing and new businesses to showcase their ideas, connect with potential partners and attract investment .
            </div>
          </div>
          <p>
          </p>
          <div class="r-button r-button-3"
               @click="$router.push({name: 'IDC/details'})">
            {{$t('readMore')}}
          </div>
        </div>
      </div>
    </div>
    <div class="idc-third">
      <div class="idc-third-title">
        {{$t('IDC_third1')}}
      </div>
      <div class="idc-third-subtitle">
        {{$t('IDC_third2')}}
      </div>
      <div class="idc-third-container">
        <div class="idc-third-container-item">
          <img src="../assets/Time.png"
               alt="">
          <p>
            {{$t('IDC_third3_1')}}
          </p>
          <p>
            {{$t('IDC_third3_2')}} <br />
            {{$t('IDC_third3_3')}} <br />
            {{$t('IDC_third3_4')}} <br />
            {{$t('IDC_third3_5')}} <br />
            {{$t('IDC_third3_6')}} <br />
            {{$t('IDC_third3_7')}}
          </p>
        </div>
        <div class="idc-third-container-item">
          <img src="../assets/Server.png"
               alt="">
          <p>
            {{$t('IDC_third4_1')}}
          </p>
          <p>
            {{$t('IDC_third4_2')}} <br />
            {{$t('IDC_third4_3')}} <br />
            {{$t('IDC_third4_4')}} <br />
            {{$t('IDC_third4_5')}} <br />
            {{$t('IDC_third4_6')}}
          </p>
        </div>
        <div class="idc-third-container-item">
          <img src="../assets/Settings.png"
               alt="">
          <p>
            {{$t('IDC_third5_1')}}
          </p>
          <p>
            {{$t('IDC_third5_2')}} <br />
            {{$t('IDC_third5_3')}} <br />
            {{$t('IDC_third5_4')}} <br />
            {{$t('IDC_third5_5')}}
          </p>
        </div>
      </div>
      <div class="r-button r-button-5"
           @click="toProduction('design')">
        {{$t('Q67')}}
      </div>
    </div>
    <div class="idc-forth">
      <div class="idc-forth-container">
        <div class="idc-forth-container-title">
          <div :class="['idc-forth-container-title-item', activedIndex === index ? 'title-actived': '']"
               v-for="(item, index) in titleList"
               :key=index
               @click="clickItem(item, index)">
            {{$t(item.title)}}
          </div>
        </div>
        <div class="idc-forth-container-video">
          <video-player class="video-player vjs-custom-skin"
                        ref="videoPlayer0"
                        :playsinline="true"
                        :options="playerOptions"></video-player>
        </div>

        <!-- <div class="swiper-wrapper">
          <div class="swiper-slide"> -->
        <!-- <img src="../assets/i1.png"
                 alt=""> -->
        <!-- <div class="swiper-slide-video">
              <video-player class="video-player vjs-custom-skin"
                            ref="videoPlayer0"
                            :playsinline="true"
                            :options="playerOptions"></video-player>
            </div> -->
        <!-- <transition name="fade">
              <div v-show="showSwiperHover"
                   class="swiper-slide-hover">
                <div class="swiper-slide-hover-title">
                  <div class="swiper-slide-hover-title-item title-actived"
                       @click="clickSwiperHover(0)">
                    {{$t('IDC_forth1_1')}}
                  </div>
                  <div class="swiper-slide-hover-title-item"
                       @click="clickSwiperHover(1)">
                    {{$t('IDC_forth1_2')}}
                  </div>
                  <div class="swiper-slide-hover-title-item"
                       @click="clickSwiperHover(2)">
                    {{$t('IDC_forth1_3')}}
                  </div>
                </div> -->
        <!-- <div class="swiper-slide-hover-container"
                     @click="$router.push({name: 'demandViewGU'})">
                  <img src="../assets/ibc-s-1.png"
                       alt="">
                  <p> -->
        <!-- {{$t('IDC_forth2_1')}} <br /> -->
        <!-- {{$t('IDC_forth2_2')}}
                  </p>
                  <p>
                    {{$t('IDC_forth2_3')}} <br />
                    {{$t('IDC_forth2_4')}} <br />
                    {{$t('IDC_forth2_5')}} <br />
                    {{$t('IDC_forth2_6')}}
                  </p>
                  <div class="r-button-wrapper">
                    <div class="r-button"
                         @click.stop="toProduction('design')">
                      {{$t('Q67')}}
                    </div>
                    <div class="r-button"
                         @click.stop="toMoreDemands"
                         v-if="$Cookies.get('cecc-lang') !== 'en'">
                      {{$t('moreDemands')}}
                    </div>
                  </div>
                </div> -->
      </div>
      <!-- </transition>
          </div>
          <div class="swiper-slide"> -->
      <!-- <img src="../assets/i2.png"
                 alt=""> -->
      <!-- <div class="swiper-slide-video">
              <video-player class="video-player vjs-custom-skin"
                            ref="videoPlayer1"
                            :playsinline="true"
                            :options="playerOptions1"></video-player>
            </div>
            <transition name="fade">
              <div v-show="showSwiperHover"
                   class="swiper-slide-hover">
                <div class="swiper-slide-hover-title">
                  <div class="swiper-slide-hover-title-item"
                       @click="clickSwiperHover(0)">
                    {{$t('IDC_forth1_1')}}
                  </div>
                  <div class="swiper-slide-hover-title-item title-actived"
                       @click="clickSwiperHover(1)">
                    {{$t('IDC_forth1_2')}}
                  </div>
                  <div class="swiper-slide-hover-title-item"
                       @click="clickSwiperHover(2)">
                    {{$t('IDC_forth1_3')}}
                  </div>
                </div> -->
      <!-- <div class="swiper-slide-hover-container"
                     @click="$router.push({name: 'demandViewNan'})">
                  <img src="../assets/ibc-s-2.png"
                       alt="">
                  <p> -->
      <!-- {{$t('IDC_forth3_1')}} <br /> -->
      <!-- {{$t('IDC_forth3_2')}}
                  </p>
                  <p>
                    {{$t('IDC_forth3_3')}} <br />
                    {{$t('IDC_forth3_4')}} <br />
                    {{$t('IDC_forth3_5')}} <br />
                    {{$t('IDC_forth3_6')}} <br />
                    {{$t('IDC_forth3_7')}} <br />
                    {{$t('IDC_forth3_8')}} <br />
                    {{$t('IDC_forth3_9')}}
                  </p>
                  <div class="r-button r-button-4"
                       @click.stop="toProduction('product')">
                    {{$t('Q67')}}
                  </div>
                </div> -->
      <!-- </div>
            </transition>
          </div>
          <div class="swiper-slide"> -->
      <!-- <img src="../assets/i3.png"
                 alt=""> -->
      <!-- <div class="swiper-slide-video">
              <video-player class="video-player vjs-custom-skin"
                            ref="videoPlayer2"
                            :playsinline="true"
                            :options="playerOptions2"></video-player>
            </div>
            <transition name="fade">
              <div v-show="showSwiperHover"
                   class="swiper-slide-hover">
                <div class="swiper-slide-hover-title">
                  <div class="swiper-slide-hover-title-item"
                       @click="clickSwiperHover(0)">
                    {{$t('IDC_forth1_1')}}
                  </div>
                  <div class="swiper-slide-hover-title-item"
                       @click="clickSwiperHover(1)">
                    {{$t('IDC_forth1_2')}}
                  </div>
                  <div class="swiper-slide-hover-title-item title-actived"
                       @click="clickSwiperHover(2)">
                    {{$t('IDC_forth1_3')}}
                  </div>
                </div> -->
      <!-- <div class="swiper-slide-hover-container">
                  <img src="../assets/ibc-s-3.png"
                       alt="">
                  <p> -->
      <!-- {{$t('IDC_forth4_1')}}<br /> -->
      <!-- {{$t('IDC_forth4_2')}}
                  </p>
                  <p>
                    {{$t('IDC_forth4_3')}} <br />
                    {{$t('IDC_forth4_4')}} <br />
                    {{$t('IDC_forth4_5')}} <br />
                    {{$t('IDC_forth4_6')}} <br />
                    {{$t('IDC_forth4_7')}} <br />
                    {{$t('IDC_forth4_8')}}
                  </p> -->
      <!-- <div class="r-button r-button-4"
                       @click="toProduction('brand')">
                    {{$t('Q67')}}
                  </div>
                </div> -->
      <!-- </div>
            </transition>
          </div>
    </div>
    </div> -->
    </div>
    <div class="idc-fifth">
      <div class="idc-fifth-title">
        {{$t('D44')}}
      </div>
      <div class="idc-fifth-subtitle">
        {{$t('D45')}}
      </div>
      <div class="idc-fifth-container">
        <div class="idc-fifth-container-item">
          <div class="idc-fifth-container-item-top">
            <img src="../assets/idc_Ram.png"
                 alt="">
          </div>
          <p>
            {{$t('H19')}}
          </p>
          <p>
            {{$t('H20')}}
          </p>
          <p>
            {{$t('H21')}} <br />
            {{$t('H22')}} <br />
            {{$t('H23')}} <br />
            {{$t('H24')}} <br />
            {{$t('H25')}} <br />
            {{$t('H26')}} <br />
            {{$t('H27')}} <br />
            {{$t('H28')}}
          </p>
        </div>
        <div class="idc-fifth-container-item">
          <div class="swiper-slide-item">
            <div class="idc-fifth-container-item-top">
              <img src="../assets/idc_liu.png"
                   alt="">
            </div>
            <p>
              {{$t('H29')}}
            </p>
            <p>
              {{$t('H30')}}
            </p>
            <p>
              {{$t('H31')}}<br />
              {{$t('H32')}}<br />
              {{$t('H33')}}<br />
              {{$t('H34')}}<br />
              {{$t('H35')}}<br />
              {{$t('H36')}}<br />
              {{$t('H37')}}<br />
              {{$t('H38')}}
            </p>
          </div>
        </div>
        <div class="idc-fifth-container-item">
          <div class="swiper-slide-item">
            <div class="idc-fifth-container-item-top">
              <img src="../assets/idc_li.png"
                   alt="">
            </div>
            <p>
              {{$t('H39')}}
            </p>
            <p>
              {{$t('H40')}}
            </p>
            <p>
              {{$t('H41')}}<br />
              {{$t('H42')}}<br />
              {{$t('H43')}}<br />
              {{$t('H44')}}<br />
              {{$t('H45')}}<br />
              {{$t('H46')}}<br />
              {{$t('H47')}}
            </p>
          </div>
        </div>
        <div class="idc-fifth-container-item">
          <div class="swiper-slide-item">
            <div class="idc-fifth-container-item-top">
              <img src="../assets/idc_eloi.png"
                   alt="">
            </div>
            <p>
              {{$t('H48')}}
            </p>
            <p>
              {{$t('H49')}}
            </p>
            <p>
              {{$t('H50')}} <br />
              {{$t('H51')}}<br />
              {{$t('H52')}}<br />
              {{$t('H53')}}<br />
              {{$t('H54')}}<br />
              {{$t('H55')}}<br />
              {{$t('H56')}}
            </p>
          </div>
        </div>
      </div>
      <div class="r-button r-button-5"
           @click="linkTo('IDC/masters')">
        {{$t('D46')}}
      </div>
    </div>
    <div class="idc-second">
      <div class="idc-second-title">
        主题需求作品展示
      </div>

      <div class="fearless-center-forth-container">
        <div class="fearless-center-forth-container-item">
          <div class="first-second">
            <img src="../assets/IDC/guyan.jpeg"
                 alt="">
          </div>
          <div class="second-second">
            <p>古堰画乡</p>
            <p>
              秀丽的瓯江风光、千年古樟树群、古镇、古村落构成的美景，形象地表达了真山真水自然古朴的江南古镇。为顺应古堰画乡景区发展的战略目标，面向社会各界征集彰显本土文化特色与生活气息的文化创意设计作品。
            </p>
            <p @click="routerTo('guyan')">
              {{$t('readMore')}}
            </p>
          </div>
        </div>
        <div class="fearless-center-forth-container-item">
          <div class="first-second">
            <img src="../assets/IDC/nanxiang.jpg"
                 alt="">
          </div>
          <div class="second-second">
            <p>南翔智地</p>
            <p>
              在上海市建设全球科创中心的过程中，上海南翔智地创新创业总部园通过汇聚各种创新要素构建完善的企业生态环境，形成创新创业企业的高度集聚，是一个集办公、研发、商业配套为一体的综合性创新创业社区。
            </p>
            <p @click="routerTo('nanxiang')">
              {{$t('readMore')}}
            </p>
          </div>
        </div>
        <div class="fearless-center-forth-container-item">
          <div class="first-second">
            <img src="../assets/IDC/dongfang.jpeg"
                 alt="">
          </div>
          <div class="second-second">
            <p>东方美谷</p>
            <p>
              上海东方美谷产业研究院以科技创新为动力，以东方美谷产业园区为依托，以化妆品行业的技术研发和人才培养为己任，通过产学研合作，促进科技成果转移转化，为化妆品行业提高全方位技术服务。
            </p>
            <p @click="routerTo('dongfang')">
              {{$t('readMore')}}
            </p>
          </div>
        </div>
      </div>

    </div>
    <Common-activies></Common-activies>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
// import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import CommonActivies from '../components/common-activities';
import CommonFooter from '../components/common-footer';

export default {
  name: 'idc',
  data () {
    return {
      showSwiperHover: false,
      ckickSwiper: null,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "",
          src: window.location.origin + '/video/1.mp4'//url地址
          // src: ''
        }],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,//当前时间和持续时间的分隔符
          durationDisplay: true,//显示持续时间
          remainingTimeDisplay: false,//是否显示剩余时间功能
          fullscreenToggle: true  //全屏按钮
        }
      },
      titleList: [
        {
          title: 'IDC_forth1_1',
          url: window.location.origin + '/video/1.mp4'
          // url: 'https://source.z1creative.com/hsbc/video/1.mp4'
        },
        {
          title: 'IDC_forth1_2',
          url: 'https://source.z1creative.com/hsbc/video/2.mp4'
        },
        {
          title: 'IDC_forth1_3',
          url: window.location.origin + '/video/3.mp4'
          // url: 'https://source.z1creative.com/hsbc/video/3.mp4'
        }
      ],
      activedIndex: 0
    }
  },
  created () {
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll);
    // this.playerOptions.sources[0].src = 'https://source.z1creative.com/hsbc/video/1.mp4';
    // this.ckickSwiper = new Swiper('.idc-forth-container');
  },
  computed: {
  },
  methods: {
    handleScroll () {
      let scrollY = document.documentElement.scrollTop
      if (scrollY > 1900 && !this.showSwiperHover) {
        setTimeout(() => {
          this.showSwiperHover = true;
        }, 1000)
      }
    },
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    },
    clickSwiperHover (index) {
      this.playerOptions.sources[0].src = `https://source.z1creative.com/hsbc/video/${index + 1}.mp4`;
      // this.ckickSwiper.slideTo(index, 100, false);
    },
    clickItem (item, index) {
      this.playerOptions.sources[0].src = item.url;
      this.activedIndex = index;
    },
    toProduction (index) {
      this.$router.push({
        name: 'awardsList',
        params: {
          from: 'IDC',
          name: index
        }
      })

      // this.$router.push({
      //   name: 'personCenter',
      //   params: {
      //     from: 'IDC',
      //     type: index
      //   }
      // })
    },
    toMoreDemands () {
      // if (JSON.parse(this.$Cookies.get('cecc-user')).org_type === 1) {
      // this.$router.push({
      //   name: 'personCenter',
      //   params: {
      //     from: 'moreDemands'
      //   }
      // })
      this.$router.push({
        name: 'demandList'
      })
      // } else {
      //   this.$message.warning('该功能仅对企业用户开放，请使用企业账号登录或注册');
      // }
    },
    routerTo (item) {
      this.$router.push({
        name: 'showList',
        params: {
          type: item
        }
      })
    },
    toRoles () {
      this.$router.push({
        name: 'personCenterCopy'
      })
    },
    toWork (index) {
      this.$router.push({
        name: 'personCenter',
        params: {
          from: 'IDC',
          type: index
        }
      })
    },

  },
  components: {
    CommonActivies,
    CommonFooter
  }
}
</script>
